// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { LookupComponent } from './lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BizDatatableModule } from '../biz-datatable/biz-datatable.module';
import { SimpleNotificationsModule } from 'angular2-notifications';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, BizDatatableModule, SimpleNotificationsModule.forRoot()
    ],
    declarations: [
        LookupComponent,
    ],
    exports: [
        LookupComponent,
    ],
})
export class LookupModule {

}
