// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { PaymentExpenseLookupComponent } from './payment-expense-lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BizDatatableModule } from '../biz-datatable/biz-datatable.module';
import { PagesContainersModule } from 'src/app/containers/pages/pages.containers.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, BizDatatableModule, PagesContainersModule
    ],
    declarations: [
        PaymentExpenseLookupComponent,
    ],
    exports: [
        PaymentExpenseLookupComponent,
    ]
})
export class PaymentExpenseLookupModule {

}
