import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment.prod';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';



@Injectable({
    providedIn: 'root'
})
export class BaseService {

    protected formBuilder: FormBuilder;
    public myForm: FormGroup;
    protected urlCollection: any;

    apiUrl: string = environment.apiUrl;
    headers = new HttpHeaders();
    serverUrl: string = environment.apiUrl;
    private deviceInfo: DeviceInfo;
    constructor(private httpclient: HttpClient, private loader: NgxUiLoaderService, private device: DeviceDetectorService) {
        this.headers = this.headers.append("Content-Type", "application/json");
        this.headers = this.headers.append("Access-Control-Allow-Origin", "*");
        this.headers = this.headers.append("Access-Control-Allow-Credentials", "true");
        this.headers = this.headers.append("Access-Control-Allow-Headers", "Content-Type");
        this.deviceInfo = this.device.getDeviceInfo();
    }

    async post(controller, method, data, moduleName?: any, isloader: boolean = true, spinner?: any) {
        let nav, currentTab, label: any;
        let labeltext: any;
        if (isloader) {
            nav = document.getElementsByName('tabs');
            if (nav && nav.length && nav.length > 0) {
                currentTab = Array.from(nav).filter((i: any) => i.checked === true)[0];
                if (currentTab && currentTab.nextElementSibling && currentTab.nextElementSibling.children && currentTab.nextElementSibling.children.length === 1) {
                    label = currentTab.nextSibling;
                    if (label) {
                        labeltext = label.innerHTML;
                        label.classList.add('loader-state');
                        label.classList.add('show-spinner');
                        label.innerHTML = `<div class="spinner d-inline-block" style="z-index: 10;">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div> <div class="label-opacity"> ${labeltext} </div> `;

                    }
                }
            }
        }
        if (moduleName) {
            await this.getData();
            this.apiUrl = this.urlCollection[moduleName];
        }
        else {
            this.apiUrl = environment.apiUrl;
        }
        let header = {
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
        }
        let url = this.apiUrl + controller + "/" + method
        // if (this.loader && isloader) this.loader.start();
        if (data && data.length && data.length > 0) {
            data.map(i => {
                i.APIUrl = url;
                i.MenuName = localStorage.getItem('menuObjectName')
                i.Source = this.deviceInfo ? this.deviceInfo.deviceType : '';
                i.App_Ver = '1.1';
                i.ComputerName = '';
                i.OS = this.deviceInfo ? this.deviceInfo.os : '';
                return i;
            })
        } else {
            data.APIUrl = url;
            data.MenuName = localStorage.getItem('menuObjectName')
            data.Source = this.deviceInfo ? this.deviceInfo.deviceType : '';
            data.App_Ver = '1.1';
            data.ComputerName = 'a';
            data.OS = this.deviceInfo ? this.deviceInfo.os : '';
        }
        if (isloader) this.loader.startBackground()
        return await this.httpclient.post(url, data, { headers: this.headers }).toPromise().then((respone) => {
            if (isloader) this.loader.stopBackground()
            // if (this.loader) this.loader.stop();
            if (isloader && label) {
                label.classList.remove('loader-state');
                label.classList.remove('show-spinner');
                label.innerHTML = labeltext;
            }
            return respone;
        }).catch((error) => {
            if (isloader) this.loader.stopBackground()
            if (isloader && label) {
                label.classList.remove('loader-state');
                label.classList.remove('show-spinner');
                label.innerHTML = labeltext;
            }
            return error;
        })
    }

    async add(url, data, moduleName?: any) {

        const headers = new HttpHeaders()
            .set('token', environment.token);

        return await this.httpclient.post(this.serverUrl + url, data, { headers: headers });
    }

    async get(controller, method, params, moduleName?: any, isloader: boolean = true, spinner?: any) {
        let nav, currentTab, label: any;
        let labeltext: any;
        if (isloader) {
            nav = document.getElementsByName('tabs');
            if (nav && nav.length && nav.length > 0) {
                currentTab = Array.from(nav).filter((i: any) => i.checked === true)[0];
                if (currentTab && currentTab.nextElementSibling && currentTab.nextElementSibling.children && currentTab.nextElementSibling.children.length === 1) {
                    label = currentTab.nextSibling;
                    if (label) {
                        labeltext = label.innerHTML;
                        label.classList.add('loader-state');
                        label.classList.add('show-spinner');
                        label.innerHTML = `<div class="spinner d-inline-block" style="z-index: 10;">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div> <div class="label-opacity"> ${labeltext} </div> `;

                    }
                }
            }
        }
        if (moduleName) {
            await this.getData();

            this.apiUrl = this.urlCollection[moduleName];
        }
        else {
            this.apiUrl = environment.apiUrl;
        }
        let keys = params ? "?" + params : '';
        // let url = this.apiUrl + controller + "/" + method + keys;
        // if (this.loader && isloader) this.loader.start();
        let url = this.apiUrl + controller + "/" + method + keys;
        if (params)
            url = url + '&APIUrl=' + this.apiUrl + controller + "/" + method + '&MenuName=' + localStorage.getItem('menuObjectName') +
                '&Source=' + this.deviceInfo.deviceType + '&App_Ver=1.1' + '&ComputerName=' + 'a' + '&OS=' + this.deviceInfo.os;
        if (isloader) this.loader.startBackground()
        return await this.httpclient.get<any>(url, { headers: this.headers }).toPromise().then(x => {
            if (isloader) this.loader.stopBackground()
            if (isloader && label) {
                label.classList.remove('loader-state');
                label.classList.remove('show-spinner');
                label.innerHTML = labeltext;
            }
            // if (this.loader) this.loader.stop();
            return x;
        }).catch((error) => {
            if (isloader) this.loader.stopBackground()
            if (isloader && label) {
                label.classList.remove('loader-state');
                label.classList.remove('show-spinner');
                label.innerHTML = labeltext;
            }
            // if (this.loader) this.loader.stop();
            return error;
        });
    }

    async delete(controller, method, key, moduleName?: any, isloader: boolean = true, spinner?: any) {
        let nav, currentTab, label: any;
        let labeltext: any;
        if (isloader) {
            nav = document.getElementsByName('tabs');
            if (nav && nav.length && nav.length > 0) {
                currentTab = Array.from(nav).filter((i: any) => i.checked === true)[0];
                if (currentTab && currentTab.nextElementSibling && currentTab.nextElementSibling.children && currentTab.nextElementSibling.children.length === 1) {
                    label = currentTab.nextSibling;
                    if (label) {
                        labeltext = label.innerHTML;
                        label.classList.add('loader-state');
                        label.classList.add('show-spinner');
                        label.innerHTML = `<div class="spinner d-inline-block" style="z-index: 10;">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div> <div class="label-opacity"> ${labeltext} </div> `;

                    }
                }
            }
        }
        if (moduleName) {
            await this.getData();
            this.apiUrl = this.urlCollection[moduleName];
        }
        else {
            this.apiUrl = environment.apiUrl;
        }

        let url = this.apiUrl + controller + "/" + method + "?" + key;
        url = url + '&APIUrl=' + this.apiUrl + controller + "/" + method + '&MenuName=' + localStorage.getItem('menuObjectName') +
            '&Source=' + this.deviceInfo.deviceType + '&App_Ver=1.1' + '&ComputerName=' + '' + '&OS=' + this.deviceInfo.os;

        let header = {
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
        // if (this.loader && isloader) this.loader.start();
        if (isloader) this.loader.startBackground()
        return await this.httpclient.delete(url).toPromise().then(x => {
            // if (this.loader) this.loader.stop();
            if (isloader) this.loader.stopBackground()
            if (isloader && label) {
                label.classList.remove('loader-state');
                label.classList.remove('show-spinner');
                label.innerHTML = labeltext;
            }
            return x;
        }).catch((error) => {
            if (isloader) this.loader.stopBackground()
            if (isloader && label) {
                label.classList.remove('loader-state');
                label.classList.remove('show-spinner');
                label.innerHTML = labeltext;
            }
            return error;
        });
    }

    async getData() {

        await this.httpclient
            .get("../../../../../assets/JSON/apiURL.json").toPromise().then((res: any) => {

                if (res && res.urls)
                    this.urlCollection = res.urls;
            });

    }

    async postReport(controller, method, data, moduleName?: any, isloader: boolean = true) {
        let nav, currentTab, label: any;
        let labeltext: any;
        if (isloader) {
            nav = document.getElementsByName('tabs');
            if (nav && nav.length && nav.length > 0) {
                currentTab = Array.from(nav).filter((i: any) => i.checked === true)[0];
                if (currentTab && currentTab.nextElementSibling && currentTab.nextElementSibling.children && currentTab.nextElementSibling.children.length === 1) {
                    label = currentTab.nextSibling;
                    if (label) {
                        labeltext = label.innerHTML;
                        label.classList.add('loader-state');
                        label.classList.add('show-spinner');
                        label.innerHTML = `<div class="spinner d-inline-block" style="z-index: 10;">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div> <div class="label-opacity"> ${labeltext} </div> `;

                    }
                }
            }
        }

        if (moduleName) {
            await this.getData();
            this.apiUrl = this.urlCollection[moduleName];
        }
        else {
            this.apiUrl = environment.apiUrl;
        }

        // this.apiUrl = 'https://realstatebuilder.bizsoft.app/api/'
        //  this.apiUrl = 'http://localhost:4101/api/'
        // this.apiUrl = 'https://api-reportmanager.bizsoft.app/api/'
        let url = this.apiUrl + controller + "/" + method
        if (isloader) this.loader.startBackground()
        return this.httpclient.post(url, data, { headers: this.headers, responseType: 'arraybuffer' })
            .toPromise().then(respone => {
                if (isloader) this.loader.stopBackground()
                if (isloader && label) {
                    label.classList.remove('loader-state');
                    label.classList.remove('show-spinner');
                    label.innerHTML = labeltext;
                }

                return respone;
            })
            .catch(e => {
                if (isloader) this.loader.stopBackground()
                if (isloader && label) {
                    label.classList.remove('loader-state');
                    label.classList.remove('show-spinner');
                    label.innerHTML = labeltext;
                }
                return e;
            })
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }

    async postRespone(controller, method, data, moduleName?: any, isloader: boolean = true) {

        let nav, currentTab, label: any;
        let labeltext: any;
        if (isloader) {
            nav = document.getElementsByName('tabs');
            if (nav && nav.length && nav.length > 0) {
                currentTab = Array.from(nav).filter((i: any) => i.checked === true)[0];
                if (currentTab && currentTab.nextElementSibling && currentTab.nextElementSibling.children && currentTab.nextElementSibling.children.length === 1) {
                    label = currentTab.nextSibling;
                    if (label) {
                        labeltext = label.innerHTML;
                        label.classList.add('loader-state');
                        label.classList.add('show-spinner');
                        label.innerHTML = `<div class="spinner d-inline-block" style="z-index: 10;">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div> <div class="label-opacity"> ${labeltext} </div> `;

                    }
                }
            }
        }

        if (moduleName) {
            await this.getData();
            this.apiUrl = this.urlCollection[moduleName];
        }
        else {
            this.apiUrl = environment.apiUrl;
        }

        // this.apiUrl = 'https://realstatebuilder.bizsoft.app/api/'
        //  this.apiUrl = 'http://localhost:4101/api/'
        // this.apiUrl = 'https://api-reportmanager.bizsoft.app/api/'
        let url = this.apiUrl + controller + "/" + method + "?" + data;
        if (isloader) this.loader.startBackground()
        return this.httpclient.post(url, data, { headers: this.headers, responseType: 'arraybuffer' })
            .toPromise().then(respone => {
                if (isloader) this.loader.stopBackground()
                if (isloader && label) {
                    label.classList.remove('loader-state');
                    label.classList.remove('show-spinner');
                    label.innerHTML = labeltext;
                }

                return respone;
            })
            .catch(e => {
                if (isloader) this.loader.stopBackground()
                if (isloader && label) {
                    label.classList.remove('loader-state');
                    label.classList.remove('show-spinner');
                    label.innerHTML = labeltext;
                }
                return e;
            })
    }


}