// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// This Module's Components
import { BizDatatableModule } from '../biz-datatable/biz-datatable.module';
import { PagesContainersModule } from '../../../../../containers/pages/pages.containers.module';
import { EmployeesForVoucherComponent } from './employees-for-voucher.component';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, BizDatatableModule, PagesContainersModule
    ],
    declarations: [
        EmployeesForVoucherComponent,
    ],
    exports: [
        EmployeesForVoucherComponent,
    ]
})
export class EmployeesForVoucherModule {

}
