import { Component, ComponentFactory, ComponentFactoryResolver, ElementRef, forwardRef, Input, ViewChild, ViewContainerRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ControlBase } from '../config';
import { LookupComponent, LookupResult } from '../lookup/lookup.component';
import { Broadcast } from '../../Services/broadcast.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'biz-dropdown',
    templateUrl: 'biz-dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BizDropdownComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BizDropdownComponent),
            multi: true,
        },
        {
            provide: ControlBase,
            useExisting: BizDropdownComponent,
        },
    ],
})
export class BizDropdownComponent extends ControlBase
    implements ControlValueAccessor, Validator, AfterViewInit {
    @Output() initialized: EventEmitter<BizDropdownComponent> = new EventEmitter<BizDropdownComponent>();
    protected lookupSubscription: Subscription;
    @Output()
    onDelete: EventEmitter<any> = new EventEmitter<any>();
    constructor(private element: ElementRef,
        private resolver: ComponentFactoryResolver,
        private broadcast: Broadcast) {
        super()
    }

    ngAfterViewInit() {
        if (this.design === "3") {

        }
        setTimeout(() => {

            this.initialized.emit(this);
        }, 0);
    }

    @ViewChild("dropdownlookup", { read: ViewContainerRef }) container: any;
    private onChange = (value: any) => { };
    private onTouched = () => { };
    // onChange = (value: any) => { };
    // onTouched = () => { };
    innerValue: any;
    @Input()
    type: number = 1;

    @Input()
    design: any = 1;

    @Input()
    config: any[] = [];

    @Input()
    valueMember: string = "";

    @Input()
    displayMember: string = "";

    @Input()
    caption: string = "";

    @Input()
    fieldName: string = "";

    @Input()
    disabled: boolean = false;

    @Input()
    listerColumns: any[] = [];

    selectedRows: any;
    _width: any;
    @Input()
    get width(): any {

        return this._width;
    }
    set width(v: any) {

        if (v) {

            this._width = v - 45 + 'px'
        }
    }

    @ViewChild('lookup') lookup: LookupComponent;

    @Input()
    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {
        if (v && v !== this.innerValue) {

            this.innerValue = v;
            this.onChange(v);
        } else {
            this.innerValue = undefined;
            this.onChange(undefined);
        }
    }

    controlEl: any
    errMsg: any;
    dirtytext: boolean = false;
    isInvalid: boolean = false;

    _data: any[] = [];
    get data(): any[] {
        return this._data;
    }

    @Input()
    set data(value: any[]) {
        this._data = value;
    }

    validate(control: AbstractControl): ValidationErrors | null {

        this.dirtytext = false
        this.controlEl = control

        // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && control.errors && control.errors.required) {
            this.errMsg = "error"

            this.isInvalid = true
            return { invalid: true }
        }
        else {

            this.errMsg = null
            this.isInvalid = false
        }
        return null;
    }

    getErrorDescrption(caption: any) {

    }

    writeValue(value: any) {
        debugger
        if (value !== this.innerValue) {

            this.innerValue = value;
        }
    }

    async Modelchange() {

        var val = Number(this.value);
        if (!isNaN(val)) this.value = val;
        //this.value = Number(this.value);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {

        this.onTouched = fn;
    }

    onBlur() {
        var requiredField;

        if (
            this.controlEl &&
            this.controlEl.errors &&
            this.controlEl.errors.required
        ) {
            requiredField = this.controlEl.errors.required;
        }
        if (
            (this.innerValue === null ||
                this.innerValue === "" ||
                this.innerValue === undefined) &&
            requiredField
        ) {
            // console.log(this.innerValue.matchAll(this.inputMask))

            this.errMsg = "";
            this.dirtytext = true;
        } else {
            this.errMsg = null;
            this.dirtytext = false;
        }
        this.onTouched();
    }
    componentRef: any;
    openlister() {

        // if (!this.disabled) {
        if (this.broadcast)
            this.lookupSubscription = this.broadcast
                .observable<LookupResult>("lookup")
                .subscribe((arg: any) => {
                    switch (arg.value.lookupId) {
                        case this.fieldName:
                            this.value = arg.value.data[this.valueMember];
                            this.triggerChanged();
                            break;
                    }
                });
        this.container.clear();

        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
            LookupComponent
        );

        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.columns = this.config;
        this.componentRef.instance.rows = this.data;
        this.componentRef.instance.type = 4;
        this.componentRef.instance.caption = this.caption ? this.caption : '';

        this.componentRef.instance.onClick.subscribe((val: any) => {

            if (val && val.row) {

                this.value = val.row.row[this.valueMember];

                // this.Modelchange();
                this.triggerChanged();

            }
        });

        this.componentRef.instance.open();
        // alert('lister')
        // }
    }

    public triggerChanged() {
        if (this.lookupSubscription) this.lookupSubscription.unsubscribe();
        let event: any = new CustomEvent("change", { bubbles: true });
        // As Renderer is not working with Angular 10 so I use this method so it will invoke when lister select event fire

        event.value = this.value

            (this.element.nativeElement as any)["dispatchEvent"].apply(
                this.element.nativeElement,
                [event]
            );
    }

    ondelete(event: any) {
        debugger
        if (event)
            event.target.value = "";
        this.value = "";
        this.onDelete.emit();
    }
}
