<div class="{{toggleClass}}">

    <!-- <div class="p-4">
        <div class="col-md-1">
            <button type="button" class="btn btn-success default mb-1 btn-xs pt-2" data-toggle="tooltip" title="Upload"
                (click)="OpenReport()" data-placement="top">
                <i>Report</i> </button>{{ " " }}
        </div>
    </div> -->
    <a (click)="OpenReport()" class="c-pointer theme-button"
        style=" top:  400%; color: #fff; background-color: #286ea7; border-color: #286aa7;" title="Report Builder"> <i
            class="simple-icon-printer"></i> </a>
</div>