import { Injectable } from '@angular/core';
import { UserRole } from '../../../../shared/auth.roles';


@Injectable({ providedIn: "root" })
export class MenuService {
    public getMenusParent() {
        let menu: any = localStorage.getItem('menus');
        menu = JSON.parse(menu);
        if (menu && menu.menus) {
            var result = menu.menus.filter(o => Number(o.parentID) === 0);
            if (result && result.length > 0) {

                for (let index = 0; index < result.length; index++) {
                    const main = result[index];
                    result[index].subs = [];
                    // result[index].roles = [UserRole.Admin, UserRole.Editor];

                    var sub = menu.menus.filter(o => Number(o.parentID) === Number(main.id))

                    if (sub && sub.length > 0) {

                        result[index].subs = sub;
                        for (let ind = 0; ind < sub.length; ind++) {
                            // sub[index].roles = [];
                            const element = sub[ind];
                            // sub[index].roles = [UserRole.Admin, UserRole.Editor];
                            sub[ind].subs = [];

                            let subchild = menu.menus.filter(m => Number(m.parentID) === Number(element.id));

                            sub[ind].subs = subchild
                        }
                    }
                }
            }

            return result;
        }
    }

    async getMenusByID(id) {
        let menu: any = localStorage.getItem('menus');

        menu = JSON.parse(menu);

        if (menu && menu.menus) {
            let module: any = menu.menus.filter(o => Number(o.parentID) === Number(id));

            if (id > 0 && module) {

                for (let index = 0; index < module.length; index++) {

                    const element = module[index];
                    module[index].subs = [];
                    module[index].roles = [UserRole.Admin, UserRole.Editor];
                    let subchild = menu.menus.filter(m => Number(m.parentID) === element.id);

                    module[index].subs = subchild
                }
                return module;
            }
        }
    }
}