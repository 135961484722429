import { NgModule } from "@angular/core";
import { BizInputModule } from "./biz-input/biz-input.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BizDropdownModule } from './biz-dropdown/biz-dropdown.module';
import { BizDatatableModule } from './biz-datatable/biz-datatable.module';
import { LookupModule } from './lookup/lookup.module';
import { BsModalService } from "ngx-bootstrap/modal";
import { BizEditorModule } from './biz-editor/biz-editor.module';
import { BizInputListerModule } from './biz-input-lister/biz-input-lister.module';
import { AccLookupModule } from './acc-lookup/acc-lookup.module';
import { ComponentsStateButtonModule } from '../../../../components/state-button/components.state-button.module';
import { IndentLookupModule } from './indent-lookup/indent-lookup.module';
import { InputIndentModule } from './input-indent/input-indent.module';
import { IssuanceLookupModule } from './issuance-lookup/issuance-lookup.module';
import { BizSelectModule } from './biz-select/biz-select.module';
import { LeatherLookupModule } from './leather-lookup/leather-lookup.module';
import { EmployeeLookupModule } from './employee-lookup/employee-lookup.module';
import { EmployeeInfoModule } from './employee-info/employee-info.module';
import { InquiryLookupModule } from './inquiry-lookup/inquiry-lookup.module';
import { PoLookupModule } from './po-lookup/po-lookup.module';
import { LotLookupModule } from './lot-lookup/lot-lookup.module';
import { SmpLookupModule } from './smp-lookup/smp-lookup.module';
import { PpdLookupModule } from './ppd-lookup/ppd-lookup.module';
import { GpLookupModule } from './gp-lookup/gp-lookup.module';
import { BizDatalistModule } from './biz-datalist/biz-datalist.module';
import { BizModalModule } from './biz-modal/biz-modal.module';
import { CoaForVoucherLookupModule } from './coa-for-voucher-lookup/coa-for-voucher-lookup.module';
import { ProjectForVoucherModule } from './project-for-voucher/project-for-voucher.module';
import { EmployeesForVoucherModule } from './employees-for-voucher/employees-for-voucher.module';
import { SalesordersModelItemLookupModule } from './salesorders-model-item-lookup/salesorders-model-item-lookup.module';
import { SalesorderslayerModelItemLookupModule } from './salesorderslayer-model-item-lookup/salesorderslayer-model-item-lookup.module';
import { GdnLayerLookupModule } from './gdn-layer-lookup/gdn-layer-lookup.module';
import { EggSelectionLookupModule } from './egg-selection-lookup/egg-selection-lookup.module';
import { QuickVoucherLookupModule } from './quick-voucher-lookup/quick-voucher-lookup.module';
import { PaymentExpenseLookupModule } from './payment-expense-lookup/payment-expense-lookup.module';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        BizInputModule,
        BizDropdownModule,
        BizDatatableModule,
        LookupModule,
        BizEditorModule,
        BizInputListerModule,
        AccLookupModule,
        ComponentsStateButtonModule,
        IndentLookupModule,
        InputIndentModule,
        IssuanceLookupModule,
        BizSelectModule,
        LeatherLookupModule,
        EmployeeLookupModule,
        EmployeeInfoModule,
        InquiryLookupModule,
        PoLookupModule,
        LotLookupModule,
        SmpLookupModule,
        PpdLookupModule,
        GpLookupModule,
        BizDatalistModule,
        BizModalModule,
        CoaForVoucherLookupModule,
        QuickVoucherLookupModule, 
        PaymentExpenseLookupModule,
        ProjectForVoucherModule,
        EmployeesForVoucherModule,
        SalesordersModelItemLookupModule,
        SalesorderslayerModelItemLookupModule,
        GdnLayerLookupModule,
        EggSelectionLookupModule
    ],
    declarations: [],
    exports: [
        BizInputModule,
        BizDropdownModule,
        BizDatatableModule,
        LookupModule,
        BizEditorModule,
        BizInputListerModule,
        AccLookupModule,
        IndentLookupModule,
        InputIndentModule,
        IssuanceLookupModule,
        BizSelectModule,
        LeatherLookupModule,
        EmployeeLookupModule,
        EmployeeInfoModule,
        InquiryLookupModule,
        PoLookupModule,
        LotLookupModule,
        SmpLookupModule,
        PpdLookupModule,
        GpLookupModule,
        BizDatalistModule,
        BizModalModule,
        CoaForVoucherLookupModule,
        QuickVoucherLookupModule,
        PaymentExpenseLookupModule,
        ProjectForVoucherModule,
        EmployeesForVoucherModule,
        SalesordersModelItemLookupModule,
        SalesorderslayerModelItemLookupModule,
        GdnLayerLookupModule,
        EggSelectionLookupModule
    ],
    entryComponents: [],
    providers: [BsModalService],
})
export class ComponentModule { }
