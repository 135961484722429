import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ServiceLocator } from '../../Services/locator.service';
import { BaseService } from '../../Services/base.service';
import { BizDatatableComponent } from '../biz-datatable/biz-datatable.component';
import { Enum } from '../config';


@Component({
    selector: 'employees-for-voucher',
    templateUrl: 'employees-for-voucher.component.html',
})
export class EmployeesForVoucherComponent implements OnInit, AfterViewInit {
    @Input() defaultValues: any[] = [];
    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() returnObject: any = {};
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() pagination: boolean = true;
    @Input() caption: string = "LOT";
    @Input() rowIndex: any;
    @Input() row: any;
    @Input() isdependent: any;
    itemOptionsOrders: any;
    itemOrder: any;
    buttonDisabled = false;
    buttonState = '';
    @Input()
    apiController: string = "";

    @Input()
    apiRoute: string = "";

    @Input()
    apiModuleName: string = "";

    @Input()
    params: any[] = [];
    // @Input() type: number = 1;

    @Input() public selectedRows: any[] = [];

    @ViewChild('lookupModal') lookupModal: TemplateRef<any>;
    @ViewChild('table') table: BizDatatableComponent;

    public _columns: any[] = [];
    public _type: number = 4;
    modalRef: any;
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 50
            };
            this._columns.splice(0, 0, selectCol);
        }
    }
    private baseService: any;
    constructor(private modalService: BsModalService) {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }
    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();
    articlelist: any[] = [];
    head: any[] = [];
    articleParams: any[] = [];

    async getEmployeesForVoucher() {
        let val = 'UserID=' + localStorage.getItem('UserID') + '&CompanyID=' + localStorage.getItem('CompanyID') + '&LocationID=' + localStorage.getItem('LocationID') + '&tType=' + 0;
        await this.baseService.get('Voucher', 'getEmployeesForVoucher', val, 'api-fmis').then((o: any) => {
            if (o && o.length && o.length > 0) {
                this.rows = o;
            }
        })
    }

    async ngOnInit() {
        this.itemOrder = { label: 'ID', value: 'id' };
        this.itemOptionsOrders = [
            { label: 'Employee ID', value: 'employeeID' },
            { label: 'Employee Name', value: 'firstName' },
        ];
        this.articleParams = [{ name: 'filed', value: 'name' }, { name: 'value', value: 'value' }]
    }

    async ngAfterViewInit() { }

    async open() {
        this.columns = [{ prop: 'employeeID', name: 'EmployeeID', width: 300 },
        { prop: 'firstName', name: 'FirstName', width: 300 }, 
    ];
        await this.getEmployeesForVoucher();
        setTimeout(() => {
            this.caption = this.caption && this.caption ? this.caption : 'Project Lookup'
            this.modalRef = this.modalService.show(this.lookupModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-lg',
            });

        }, 0);
    }

    @Input()
    public LookupId: String = "";

    Click(row) {
        debugger
        let obj = {
            grid: this.table,
            row: row,
            data: this.rows
        }

        if (obj) {
            if (this.modalRef)
                this.modalRef.hide()
            this.onClick.emit(obj);
        }
    }

    onSelection(selection) {
     if (selection) {
        }
    }

    save() {
        if (this.selectedRows) {
            this.modalRef.hide();
            return this.selectedRows;
        }
    }

    itemsPerPageChange(e) {
    }
    tempData: any[] = []
    async searchByButton(obj) {
        if (obj && obj.value) {
            // await this.getAllLeather(3, 0, obj.item.value, obj.value)
        }
    }

    searchKeyUp(obj): void {
        const val = obj.event.target.value;
        if (val === "" && this.tempData.length > 0) {
            this.rows = [...this.tempData]
            this.tempData = [];
        }

        if (this.tempData.length === 0) this.tempData = this.rows;
        this.rows = this.rows.filter(i => i[obj.item.value].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== - 1);
    };
}

export interface LookupResult {
    lookupId: string;
    data: any;
}



