<!--component html goes here -->

<ng-template #lookupModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{caption}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 mb-4 data-table-rows actionGrid" style="margin-bottom: 15px;">
                <!-- <biz-datatable #table [type]="3" [rows]="rows" [limit]="limit" [columns]="_columns"
                [footerHeight]="80" (onClick)="Click($event)" [selected]="selectedRows" (onSelection)='onSelection($event)' [selectionType]="'checkbox'"
                (onEdit)="Edit($event)">               
                </biz-datatable> --> 
                <biz-datatable #table [type]="3" [rows]="rows" [limit]="limit" [columns]="_columns" [hideDelete]="false"
                    [footerHeight]="80" (onClick)="Click($event)"  (onEdit)="Click($event)">
                </biz-datatable>
                      <!-- <biz-datatable #table [type]="_type" [rows]="rows" [limit]="limit" [columns]="_columns"
                    [footerHeight]="80" (onClick)="Click($event)">
                </biz-datatable> -->
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- button type="submit" class="btn btn-primary float-right" (click)="save()">Select</button> -->
    </div>
</ng-template>
<simple-notifications></simple-notifications>