<!--component html goes here -->
<div class="row">
    <div class="col-12 md-4 data-table-rows actionGrid" style="margin-bottom: 15px;" *ngIf="_type === 1">
        <div *ngIf="_type === 1" style="margin-top: 5px;margin-bottom: 5px;display: flow-root;">
            <button *ngIf="hideAddRowbtn" type="button" [disabled]="disabledAddRowbtn" style="border-radius: 5px;"
                class="btn btn-primary btn-xs" (click)="addGridRow()">Add
                Row</button>
            <div class="float-right">
                <span class="text-muted text-small">Per Page </span>
                <span dropdown class="d-inline-block position-relative">
                    <button class="btn btn-outline-dark btn-xs dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" dropdownToggle>
                        {{itemsPerPage}}
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm text-right" *dropdownMenu>
                        <li role="menuitem" *ngFor="let item of itemOptionsPerPage"><a
                                [ngClass]="item === itemsPerPage ? 'dropdown-item active' : 'dropdown-item'"
                                (click)="onChangeItemsPerPage(item)">{{item}}</a></li>
                    </div>
                </span>
            </div>
        </div>
        <ngx-datatable [limit]="itemsPerPage" [virtualization]="true" #table [columnMode]="columnMode" [rows]="_rows"
            [headerHeight]="52" [scrollbarH]="scrollbarH" [footerHeight]="footerHeight" [enableSummary]="enableSummary"
            [summaryPosition]="summaryPosition" [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-column *ngIf="isAction" frozenLeft="frozenLeft" name="Action" [flexGrow]="5"
                [width]="actionColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <button [id]="loader+rowIndex"
                        [class]="buttonState + ' ' + 'btn' + ' btn-multiple-state btnState-Action'" [popover]="message"
                        triggers="">
                        <div class="spinner d-inline-block" style="z-index: 10;">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <span class="icon success" data-toggle="tooltip" data-placement="top"
                            title="Everything went right!">
                            <i class="simple-icon-check"></i>
                        </span>
                        <span class="icon fail" data-toggle="tooltip" data-placement="top"
                            title="Something went wrong!">
                            <i class="simple-icon-exclamation"></i>
                        </span>
                        <span class="label">
                            <span *ngIf="hideSave" data-toggle="tooltip" title="Save" data-placement="top"
                                class="actionButtons" (click)="Edit(row, rowIndex)"><i class="iconsminds-save"></i>
                            </span>
                            <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top"
                                class="actionButtons" (click)="Edit(row, rowIndex)"><i class="simple-icon-note"></i>
                            </span>
                            <span *ngIf="hideView" data-toggle="tooltip" title="View" data-placement="top"
                                class="actionButtons" (click)="View(row)"><i class="simple-icon-eye"></i></span>
                            <span *ngIf="hideDelete" data-toggle="tooltip" title="Delete" data-placement="top"
                                class="actionButtons" (click)="Delete(row, rowIndex)"><i
                                    class="simple-icon-close"></i></span>
                        </span>
                    </button>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns;let indexOfelement=index;"
                [width]="item.width ? item.width : 100" [name]="item.name" [prop]="item.prop">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div style="display: inline-grid;width: 100%;">
                        <span class="datatable-header-cell-wrapper"><span
                                class="datatable-header-cell-label draggable">{{item.name}}</span></span>
                        <input type="text" class="filterinput"
                            (keyup)="keyup({value: $event.target.value, item: item})">
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <div *ngIf="item.editable">
                        <biz-input *ngIf="item.type === 1" type="text"
                            (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                            (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)"
                            [value]="value" (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)"
                            [design]="3" [disabled]="!item.editable">
                        </biz-input>
                        <biz-input *ngIf="item.type === 3" type="date"  
                            (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                            (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)"
                            [value]="value" (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)"
                            [design]="3" [disabled]="!item.editable">
                        </biz-input>
                        <biz-input *ngIf="item.type === 4" type="number"
                            (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                            (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)"
                            [value]="value" (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)"
                            [design]="3" [disabled]="!item.editable">
                        </biz-input>
                        <div *ngIf="item.type === 2">
                            <biz-dropdown [id]="item.prop+rowIndex" caption="" [value]="value"
                                (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                                (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                                [design]="5" [data]="item.dropdownData" [config]="item.config"
                                [disabled]="!item.editable" [valueMember]="item.valueMember"
                                [displayMember]="item.displayMember">
                            </biz-dropdown>
                        </div>
                        <div *ngIf="item.type === 6">
                            <biz-dropdown #item.prop+rowIndex
                                (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                                [id]="item.prop+rowIndex" caption="" [value]="value"
                                (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                                [design]="3" [data]="item.dropdownData" [config]="item.config"
                                [disabled]="!item.editable" [valueMember]="item.valueMember"
                                [displayMember]="item.displayMember">
                            </biz-dropdown>
                        </div>
                        <div *ngIf="item.type === 8">
                            <biz-inputLister #item.prop+rowIndex [rowIndex]="rowIndex" [params]="item.parameters"
                                [lookupType]="item.lookupType"
                                (BeforeOpen)="BeforeOpen($event, rowIndex, item.prop, item, row)"
                                (initialized)="inputListerInitialized($event, rowIndex, item.prop, item, row)"
                                [isdependent]="item.isListerdependent" [defaultValues]="item.defaultValues" [row]="row"
                                [id]="item.prop+rowIndex" caption="" [apiController]="item.apiController"
                                [apiRoute]="item.apiRoute" [apiModuleName]="item.apiModuleName" [value]="value"
                                (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                                [data]="item.dropdownData" [config]="item.config" [isDisabled]="item.isDisabled"
                                [inputType]="item.inputType" [design]="item.design" [valueMember]="item.valueMember"
                                [displayMember]="item.displayMember" [isButton]="item.isButton">
                            </biz-inputLister>
                        </div>
                        <div *ngIf="item.type === 10">
                            <biz-select #item.prop+rowIndex (initialized)="dropdownInitialized($event)"
                                [id]="item.prop+rowIndex" caption="" [value]="value" [lookupType]="item.lookupType"
                                (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                                [design]="4" [data]="item.dropdownData" [config]="item.config"
                                [disabled]="!item.editable" [valueMember]="item.valueMember"
                                [displayMember]="item.displayMember">
                            </biz-select>
                        </div>
                        <div *ngIf="item.type === 11">
                            <biz-datalist #item.prop+rowIndex
                                (initialized)="datalistInitialized($event,rowIndex, item.prop, item, row)"
                                [id]="item.prop+rowIndex" [elementid]="item.prop+rowIndex+item.prop" caption=""
                                [value]="value" (change)="valueChange($event, rowIndex, item.prop, item, row)"
                                [width]="item.width" [design]="3" [data]="item.dropdownData" [config]="item.config"
                                [disabled]="!item.editable" [valueMember]="item.valueMember">
                            </biz-datalist>
                        </div>
                        <button *ngIf="item.type === 5" [id]="item.prop" type="button"
                            class="btn btn-outline-primary btn-xs" style="margin-top: -3px;"
                            (click)="buttonClick(row)">{{item.name}}</button>
                        <input *ngIf="item.type === 7" type="checkbox" [checked]="value"
                            (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                            [disabled]="!item.editable"
                            style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />

                            <input *ngIf="item.type === 13" type="radio" [checked]="value"
                            (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                            [disabled]="!item.editable"
                            style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />
                    </div>
                    <div *ngIf="!item.editable"> {{value}} </div>
                </ng-template>
            </ngx-datatable-column>
            <!-- frozenRight="frozenRight" -->
            <ngx-datatable-column *ngIf="isCustomButton" frozenLeft="frozenLeft" [name]="CustomColumnName"
                [flexGrow]="5" [width]="customColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngFor="let b of _customButtonList" class="actionButtons" data-toggle="tooltip"
                        [title]="b.name" data-placement="top" (click)="customButtonClick(b, row)"><i
                            [ngClass]="b.icon ? b.icon : 'simple-icon-eye'"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="itemsPerPage">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination #InputPagination [boundaryLinks]="true" [totalItems]="rowCount" [rotate]="true"
                            [itemsPerPage]="pageSize" [maxSize]="5" class="justify-content-center"
                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
   

    <div [class]="customClass + ' ' + 'col-12 md-4 data-table-rows'" style="margin-bottom: 15px;" *ngIf="_type === 9">
        <div class="text-left" *ngIf="_type === 9" style="margin-top: 5px;margin-bottom: 5px;">
            <button accesskey="s" *ngIf="hideAddRowbtn" type="button" [disabled]="disabledAddRowbtn"
                style="border-radius: 5px;" class="btn btn-primary btn-xs" id="AddRow" (click)="addGridRow1()">Add
                Row</button>
        </div>
        <ngx-datatable rowHeight="25" offset="100" [limit]="limit" [virtualization]="false" #table [columnMode]="columnMode"
            [rows]="_rows" [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [scrollbarV]="true"
            [footerHeight]="0" [sorts]="[{ prop: 'title', dir: 'asc' }]" [ngStyle]="{ 'height': _gridHeight }">
            <ngx-datatable-column *ngIf="isAction" frozenLeft="frozenLeft" name="Action" [flexGrow]="5"
                [width]="actionColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <button [id]="loader+rowIndex"
                        [class]="buttonState + ' ' + 'btn' + ' btn-multiple-state btnState-Action'" [popover]="message"
                        triggers="">
                        <div class="spinner d-inline-block" style="z-index: 10;">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <span class="icon success" data-toggle="tooltip" data-placement="top"
                            title="Everything went right!">
                            <i class="simple-icon-check"></i>
                        </span>
                        <span class="icon fail" data-toggle="tooltip" data-placement="top"
                            title="Something went wrong!">
                            <i class="simple-icon-exclamation"></i>
                        </span>
                        <span class="label">
                            <span *ngIf="hideSave" data-toggle="tooltip" title="Save" data-placement="top"
                                class="actionButtons" (click)="Edit(row, rowIndex)"><i class="iconsminds-save"></i>
                            </span>
                            <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top"
                                class="actionButtons" (click)="Edit(row, rowIndex)"><i class="simple-icon-note"></i>
                            </span>
                            <span *ngIf="hideView" data-toggle="tooltip" title="View" data-placement="top"
                                class="actionButtons" (click)="View(row)"><i class="simple-icon-eye"></i></span>
                            <span *ngIf="hideDelete" data-toggle="tooltip" title="Delete" data-placement="top"
                                class="actionButtons" (click)="Delete(row, rowIndex)"><i
                                    class="simple-icon-close"></i></span>
                        </span>
                    </button>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns;let indexOfelement=index;"
                [width]="item.width ? item.width : 100" [name]="item.name" [prop]="item.prop">
                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <biz-input *ngIf="item.type === 1" type="text"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 3" type="date"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 4" type="number"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <div *ngIf="item.type === 2">
                        <biz-dropdown [id]="item.prop+rowIndex" caption="" [value]="value"
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="5" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 6">
                        <biz-dropdown #item.prop+rowIndex
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            [id]="item.prop+rowIndex" caption="" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="3" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 8">
                        <biz-inputLister #item.prop+rowIndex [rowIndex]="rowIndex" [params]="item.parameters"
                            [lookupType]="item.lookupType"
                            (BeforeOpen)="BeforeOpen($event, rowIndex, item.prop, item, row)"
                            (initialized)="inputListerInitialized($event, rowIndex, item.prop, item, row)"
                            [isdependent]="item.isListerdependent" [defaultValues]="item.defaultValues" [row]="row"
                            [id]="item.prop+rowIndex" caption="" [apiController]="item.apiController"
                            [apiRoute]="item.apiRoute" [apiModuleName]="item.apiModuleName" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [data]="item.dropdownData" [config]="item.config" [isDisabled]="item.isDisabled"
                            [inputType]="item.inputType" [design]="item.design" [valueMember]="item.valueMember"
                            [displayMember]="item.displayMember" [isButton]="item.isButton">
                        </biz-inputLister>
                    </div>
                    <div *ngIf="item.type === 10">
                        <biz-select #item.prop+rowIndex (initialized)="dropdownInitialized($event)"
                            [id]="item.prop+rowIndex" caption="" [value]="value" [lookupType]="item.lookupType"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="4" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-select>
                    </div>
                    <button *ngIf="item.type === 5" [id]="item.prop" type="button"
                        class="btn btn-outline-primary btn-xs" style="margin-top: -3px;"
                        (click)="buttonClick(row)">{{item.name}}</button>
                    <input *ngIf="item.type === 7" type="checkbox" [checked]="value"
                        (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                        [disabled]="!item.editable"
                        style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />
                        <input *ngIf="item.type === 13" type="radio" [value]="value"
                        (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                        [disabled]="!item.editable"
                        style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />
                    <img *ngIf="item.type === 11" [src]="value" width="90%;" style="max-height: 36px;">
                </ng-template>
            </ngx-datatable-column>
            <!-- frozenRight="frozenRight" -->
            <ngx-datatable-column *ngIf="isCustomButton" frozenLeft="frozenLeft" [name]="CustomColumnName"
                [flexGrow]="5" [width]="customColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngFor="let b of _customButtonList" class="actionButtons" data-toggle="tooltip"
                        [title]="b.name" data-placement="top" (click)="customButtonClick(b, row)"><i
                            [ngClass]="b.icon ? b.icon : 'simple-icon-eye'"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            
        </ngx-datatable>
    </div>

    <div class="col-12 md-4 data-table-rows actionGrid" style="margin-bottom: 15px;" *ngIf="_type === 3">
        <ngx-datatable #table [columnMode]="columnMode" [rows]="_rows" [limit]="limit" [headerHeight]="52"
            [scrollbarH]="scrollbarH" [scrollbarV]="scrollbarV" [footerHeight]="footerHeight"
            [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-column name="Action" frozenLeft="frozenLeft" [flexGrow]="5" [width]="actionColumnWidth"
                [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top" class="actionButtons"
                        (click)="Edit(row, rowIndex)"><i class="simple-icon-note"></i>
                    </span>
                    <span *ngIf="hideView" data-toggle="tooltip" title="View" data-placement="top" class="actionButtons"
                        (click)="View(row)"><i class="simple-icon-eye"></i></span>
                    <span *ngIf="hideDelete" data-toggle="tooltip" title="Delete" data-placement="top"
                        class="actionButtons" (click)="Delete(row)"><i class="simple-icon-close"></i></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div style="display: inline-grid;width: 100%;">
                        <span class="datatable-header-cell-wrapper"><span
                                class="datatable-header-cell-label draggable">{{item.name}}</span></span>
                        <input type="text" class="filterinput"
                            (keyup)="keyup({value: $event.target.value, item: item})">
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="isCustomButton" frozenRight="frozenRight" [name]="CustomColumnName"
                [flexGrow]="5" [width]="customColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngFor="let b of _customButtonList" class="actionButtons" data-toggle="tooltip"
                        [title]="b.name" data-placement="top" (click)="customButtonClick(b, row)"><i
                            [ngClass]="b.icon ? b.icon : 'simple-icon-eye'"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [totalItems]="rowCount" [rotate]="true"
                            [itemsPerPage]="pageSize" [maxSize]="5" class="justify-content-center"
                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class="col-12 md-4 data-table-rows actionGrid" style="margin-bottom: 15px;" *ngIf="_type === 2">
        <ngx-datatable #table [columnMode]="columnMode" [rows]="_rows" [limit]="limit" [headerHeight]="headerHeight"
            [scrollbarH]="scrollbarH" [footerHeight]="footerHeight" [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop">
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [totalItems]="rowCount" [rotate]="true"
                            [itemsPerPage]="pageSize" [maxSize]="5" class="justify-content-center"
                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class="col-12 md-4 data-table-rows actionGrid" style="margin-bottom: 15px;" *ngIf="_type === 4">
        <ngx-datatable #table [columnMode]="columnMode" [rows]="_rows" [limit]="limit" [headerHeight]="headerHeight"
            [scrollbarH]="scrollbarH" [scrollbarV]="scrollbarV" [footerHeight]="footerHeight"
            [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-column name="Action" frozenLeft="frozenLeft" [flexGrow]="5" [width]="70"
                [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top" class="actionButtons"
                        (click)="Click(row)"><i class="simple-icon-check"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop">
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [totalItems]="rowCount" [rotate]="true"
                            [itemsPerPage]="pageSize" [maxSize]="5" class="justify-content-center"
                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
    </div>

    <div class="col-12 md-4 data-table-rows actionGrid" style="margin-bottom: 15px;" *ngIf="_type === 5">
        <ngx-datatable #table [selected]="selectedRows" [columns]="_columns" [selectionType]="'checkbox'"
            (select)='Selection($event)' [columnMode]="columnMode" [rows]="_rows" [limit]="limit"
            [ngStyle]="{ 'height': _gridHeight }" [headerHeight]="headerHeight" [scrollbarV]="scrollbarV"
            [scrollbarH]="scrollbarH" [footerHeight]="footerHeight" [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [totalItems]="rowCount" [rotate]="true"
                            [itemsPerPage]="pageSize" [maxSize]="5" class="justify-content-center"
                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class="col-12 mb-4 data-grid" *ngIf="_type === 6">
        <ngx-datatable class="material" [limit]="limit" [virtualization]="false" #table [columnMode]="columnMode"
            [rows]="_rows" [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [treeFromRelation]="treeFromRelation"
            [treeToRelation]="treeToRelation" [scrollbarV]="true" (treeAction)="TreeAction($event)" [footerHeight]="0"
            [rowClass]="getRowClass" style="height: 250px;">
            <ngx-datatable-column name="" [flexGrow]="3" [isTreeColumn]="true" [width]="30">
                <ng-template ngx-datatable-tree-toggle let-tree="cellContext">
                    <i
                        [class]="tree.treeStatus === 'loading' ? 'simple-icon-options' : tree.treeStatus === 'collapsed' ? 'simple-icon-arrow-down' : tree.treeStatus === 'expanded' ? 'simple-icon-arrow-up' : tree.treeStatus === 'disabled' ? 'simple-icon-options-vertical' : ' '">

                    </i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns;let indexOfelement=index;"
                [width]="item.width ? item.width : 100" [name]="item.name" [prop]="item.prop">
                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <biz-input *ngIf="item.type === 1" type="text"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 3" type="date"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 4" type="number"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <div *ngIf="item.type === 2">
                        <biz-dropdown [id]="item.prop+rowIndex" caption="" [value]="value"
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="5" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 6">
                        <biz-dropdown #item.prop+rowIndex
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            [id]="item.prop+rowIndex" caption="" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="3" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 8">
                        <biz-inputLister #item.prop+rowIndex [rowIndex]="rowIndex" [params]="item.parameters"
                            [lookupType]="item.lookupType"
                            (BeforeOpen)="BeforeOpen($event, rowIndex, item.prop, item, row)"
                            (initialized)="inputListerInitialized($event, rowIndex, item.prop, item, row)"
                            [isdependent]="item.isListerdependent" [defaultValues]="item.defaultValues" [row]="row"
                            [id]="item.prop+rowIndex" caption="" [apiController]="item.apiController"
                            [apiRoute]="item.apiRoute" [apiModuleName]="item.apiModuleName" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [data]="item.dropdownData" [config]="item.config" [isDisabled]="item.isDisabled"
                            [inputType]="item.inputType" [design]="item.design" [valueMember]="item.valueMember"
                            [displayMember]="item.displayMember" [isButton]="item.isButton">
                        </biz-inputLister>
                    </div>
                    <div *ngIf="item.type === 10">
                        <biz-select #item.prop+rowIndex (initialized)="dropdownInitialized($event)"
                            [id]="item.prop+rowIndex" caption="" [value]="value" [lookupType]="item.lookupType"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="4" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-select>
                    </div>
                    <button *ngIf="item.type === 5" [id]="item.prop" type="button"
                        class="btn btn-outline-primary btn-xs" style="margin-top: -3px;"
                        (click)="buttonClick(row)">{{item.name}}</button>
                    <input *ngIf="item.type === 7" type="checkbox" [checked]="value"
                        (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                        [disabled]="!item.editable"
                        style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />
                        <input *ngIf="item.type === 13" type="radio" [checked]="value"
                        (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                        [disabled]="!item.editable"
                        style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />
                </ng-template>
            </ngx-datatable-column>
            <!-- frozenRight="frozenRight" -->
            <ngx-datatable-column *ngIf="isCustomButton" frozenLeft="frozenLeft" [name]="CustomColumnName"
                [flexGrow]="5" [width]="customColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngFor="let b of _customButtonList" class="actionButtons" data-toggle="tooltip"
                        [title]="b.name" data-placement="top" (click)="customButtonClick(b, row)"><i
                            [ngClass]="b.icon ? b.icon : 'simple-icon-eye'"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
    <div class="col-12 md-4 data-table-rows actionGrid" style="margin-bottom: 15px;" *ngIf="_type === 7">
        <ngx-datatable #table [columnMode]="columnMode" [rows]="_rows" [limit]="limit" [headerHeight]="headerHeight"
            [scrollbarH]="scrollbarH" [scrollbarV]="true" [footerHeight]="0" [sorts]="[{ prop: 'title', dir: 'asc' }]"
            style="height: 300px;">
            <ngx-datatable-column name="Action" frozenLeft="frozenLeft" [flexGrow]="5" [width]="70"
                [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top" class="actionButtons"
                        (click)="Click(row)"><i class="simple-icon-check"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop" [cellClass]="cellClassMethod">
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [totalItems]="rowCount" [rotate]="true"
                            [itemsPerPage]="pageSize" [maxSize]="5" class="justify-content-center"
                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class="col-12 md-4 data-table-rows actionGrid grouping" style="margin-bottom: 15px;" *ngIf="_type === 8">
        <ngx-datatable #table class="material expandable" [columnMode]="columnMode" [rows]="_rows" [limit]="limit"
            [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [scrollbarV]="scrollbarV"
            [rowClass]="generateRowClass" [footerHeight]="footerHeight" [sorts]="[{ prop: 'title', dir: 'asc' }]"
            [groupRowsBy]="groupRowsBy" [groupExpansionDefault]="groupRowExpand" style="min-height: 200px;">
            <!-- Group Header Template -->
            <ngx-datatable-group-header #myGroupHeader>
                <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                    <div style="padding-left:5px;background: #e9e6e6;cursor: pointer;">
                        <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                            title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
                            <b style="font-size: 15px;">{{groupRowsByCaption}}: {{ group.value[0][groupRowsBy] }}</b>
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-group-header>
            <ngx-datatable-column name="Action" frozenLeft="frozenLeft" [flexGrow]="5" [width]="70"
                [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top" class="actionButtons"
                        (click)="Click(row)"><i class="simple-icon-check"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop" [cellClass]="cellClassMethod">
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [boundaryLinks]="true" [totalItems]="rowCount" [rotate]="true"
                            [itemsPerPage]="pageSize" [maxSize]="5" class="justify-content-center"
                            [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-arrow-left'></i>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-end'></i>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-control-start'></i>
</ng-template>
<style>
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .filterinput {
        width: 100%;
        height: 22px;
        font-size: 11px;
        border: 1px solid #ddd;
        border-radius: 3px;
    }
</style>