import { Component, Input, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import menuItems, { IMenuItem } from 'src/app/constants/menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html'
})
export class HeadingComponent implements OnInit {
  @Input() title = '';
  menuItems: IMenuItem[] = menuItems;
  path = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          return route;
        })
      ).subscribe((event) => {
        this.path = this.router.url.split('?')[0];
      });

  }

  async ngOnInit() {
    
    let path: any = window.location.pathname;

    path = path.split('/APP');

    let activepath = path[1]
    if (!activepath) {
      let p: any = window.location.pathname
      activepath = p;
    }

    // this.title = '';
    let menuID = localStorage.getItem('menuID');
    let menu: any = localStorage.getItem('menus');
    let screenName = localStorage.getItem('screenName');
    let subMainMenu = localStorage.getItem('subMainMenu');
    menu = JSON.parse(menu);
    
    if (menu && menu.menus) {
      let menuObj = menu.menus.filter(o => o.wP_FormName === activepath);
      if (menuObj && menuObj.length && menuObj.length > 0) {
        this.title = menuObj[0].label
      } else if (screenName) {
        this.title = screenName;
      }
      else if (this.title) {
        return this.title
      }
      else {
        this.title = "notFound"
      }

    } else if (screenName) {
      this.title = screenName;
    }
    else if (this.title) {
      return this.title
    }
    else {
      this.title = "notFound"
    }
    
  }

  getLabel(path): string {
    if (path === environment.adminRoot) {
      return 'menu.home';
    }

    // step 0
    let foundedMenuItem = this.menuItems.find(x => x.to === path);

    if (!foundedMenuItem) {
      // step 1
      this.menuItems.map(menu => {
        if (!foundedMenuItem && menu.subs) { foundedMenuItem = menu.subs.find(x => x.to === path); }
      });
      if (!foundedMenuItem) {
        // step 2
        this.menuItems.map(menu => {
          if (menu.subs) {
            menu.subs.map(sub => {
              if (!foundedMenuItem && sub.subs) { foundedMenuItem = sub.subs.find(x => x.to === path); }
            });
          }
        });
        if (!foundedMenuItem) {
          // step 3
          this.menuItems.map(menu => {
            if (menu.subs) {
              menu.subs.map(sub => {
                if (sub.subs) {
                  sub.subs.map(deepSub => {
                    if (!foundedMenuItem && deepSub.subs) { foundedMenuItem = deepSub.subs.find(x => x.to === path); }
                  });
                }
              });
            }
          });
        }
      }
    }

    if (foundedMenuItem) { return foundedMenuItem.label; } else { return ''; }
  }

}
