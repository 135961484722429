import { Component, OnInit, AfterViewInit, Input, ViewChild, TemplateRef, Output, EventEmitter, ElementRef, ViewContainerRef, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { Enum } from '../config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BizDatatableComponent } from '../biz-datatable/biz-datatable.component';
import { Broadcast } from '../../Services/broadcast.service';
import { BaseService } from '../../Services/base.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BizModalComponent } from '../biz-modal/biz-modal.component';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
    selector: 'biz-lookup',
    templateUrl: 'lookup.component.html',
})
export class LookupComponent implements OnInit, AfterViewInit {

    @ViewChild("lookupTemplate", { read: ViewContainerRef }) public container: any;
    componentRef: any;
    @Input() backdrop: any = "static";
    @Input() size: string = "modal-lg";
    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() pagination: boolean = true;
    @Input() caption: string = "Lookup";
    itemOptionsOrders: any;
    itemOrder: any;
    // @Input() type: number = 1;
    @Output() initialized: EventEmitter<LookupComponent> = new EventEmitter<LookupComponent>();
    @Input() public selectedRows: any[] = [];

    @ViewChild('lookupModal') lookupModal: TemplateRef<any>;
    // @ViewChild('lookupContent')
    // set applookup(lookup: TemplateRef<any>) {
    //     this.lookupModal = lookup;
    // };
    @ViewChild('table') table: BizDatatableComponent;

    public _columns: any[] = [];
    public _type: number = 4;
    modalRef: any;
    controller: any;
    route: any;
    moduleName: any;
    parameters: any[];
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 50
            };
            this._columns.splice(0, 0, selectCol);
        }
    }

    constructor(private modalService: BsModalService, private broadcast: Broadcast, private baseService: BaseService, private isloader: NgxUiLoaderService,
        private resolver: ComponentFactoryResolver, private notification: NotificationsService) {

    }

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    async ngOnInit() { }

    async ngAfterViewInit() {
        this.initialized.emit(this)
    }

    async open(controller?: any, route?: any, parameters?: any[], module?: any) {
        let val: any;

        if (controller && route) {
            if (parameters && parameters.length > 0) {
                for (let index = 0; index < parameters.length; index++) {
                    const el = parameters[index];
                    val = val ? val + '&' + el.name + '=' + el.value : el.name + '=' + el.value

                }
            }

            let moduleName = module ? module : undefined

            this.controller = controller;
            this.route = route;
            this.moduleName = moduleName;
            this.parameters = parameters;
            this.isloader.startBackground();
            await this.baseService.get(controller, route, val, moduleName).then(o => {

                this.isloader.stopBackground();
                if (o && o.length && o.length > 0) {
                    let keys: any[] = [];
                    let object = o[0];

                    Object.keys(object).forEach((e: any) => {
                        let obj = {
                            name: e.toUpperCase(),
                            prop: e,
                        }
                        keys.push(obj);
                    })
                    this._columns = [...keys];

                    this.rows = [...o];
                } else {
                    this.notification.create('Error', 'No Record Found!',
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            });

        }

        if (this.rows.length > 0) {
            if (this.lookupModal)
                this.modalRef = this.modalService.show(this.lookupModal, {
                    animated: true,
                    backdrop: this.backdrop,
                    class: this.size,
                });

        }
    }

    @Input()
    public LookupId: String = "";

    Click(row) {

        let obj = {
            grid: this.table,
            row: row
        }
        if (obj) {
            setTimeout(() => {
                if (this.modalRef)
                    this.modalRef.hide()
            }, 0);
            this.broadcast.publish<LookupResult>("lookup", <LookupResult>{
                lookupId: this.LookupId,
                data: row,
                controller: this.controller,
                moduleName: this.moduleName
            });

            this.onClick.emit(obj);
        }
    }

    onSelection(selection) {

        if (selection) {

        }
    }

    save() {

        if (this.selectedRows) {

            this.modalRef.hide();
            return this.selectedRows;

        }
    }

    itemsPerPageChange(e) {

    }
    tempData: any[] = []
    searchKeyUp(obj) {

        const val = obj.event.target.value;

        if (val === "" && this.tempData.length > 0) {

            this.rows = [...this.tempData]
            this.tempData = [];
        }

        if (this.tempData.length === 0) this.tempData = this.rows;

        this.rows = this.rows.filter(i => i[obj.item.value].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== - 1);

    }


}

export interface LookupResult {
    lookupId: string;
    data: any;
    controller: string;
    moduleName: string;
}
