import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    moduleId: module.id,
    selector: 'biz-modal',
    templateUrl: 'biz-modal.component.html',
})
export class BizModalComponent {

    @Input() caption: string = "";
    modalRef: any;
    @ViewChild('modalContent') modal: TemplateRef<any>;
    @Input() backdrop: any = "static";
    @Input() size: string = "modal-lg";
    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    public _columns: any[] = [];
    public _type: number = 4;
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 50
            };
            this._columns.splice(0, 0, selectCol);
        }
    }
    constructor(private modalService: BsModalService) {

    }

    async open() {
        this.modalRef = this.modalService.show(this.modal, {
            animated: true,
            backdrop: this.backdrop,
            class: this.size,
        });
    }
}
