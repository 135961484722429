<!--component html goes here -->
<!-- <label class="form-group has-float-label" style="margin-top: -5px;">
    <input class="form-control" list="browsers" [disabled]="disabled" name="browsers" [(ngModel)]="value"
        style="    margin-top: -3px;
    background: transparent;
    border: none;
    width: -webkit-fill-available;" id="browsers">
    <datalist id="browsers">
        <option *ngFor="let i of _data" [value]="i.valueMember">
    </datalist>
    <span *ngIf="caption" class="texto" style="background: transparent !important">{{caption}}</span>
</label> -->

<label class="form-group has-float-label" style="margin-top: -5px;">
    <input class="form-control" [attr.list]="elementid" [(ngModel)]="value" style="    margin-top: -3px;
    background: transparent;
    border: none;
    width: -webkit-fill-available;">
    <datalist [id]="elementid">
        <option *ngFor="let i of _data" [value]="i[valueMember]">
    </datalist>
    <span *ngIf="caption" class="texto" style="background: transparent !important">{{caption}}</span>
</label>