import { Component, ViewChild, EventEmitter, Output, Input, ViewContainerRef, ComponentFactory, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ExcelService } from '../../../views/app/@core/Services/export.service';
import { BaseComponent } from '../../../views/app/@core/BaseComponent/base.component';
import { Broadcast } from '../../../views/app/@core/Services/broadcast.service';
import { LookupComponent } from '../../../views/app/@core/components/lookup/lookup.component';
import { ToolbarEvent } from '../../../views/app/@core/Services/toolbar.service';
import { ServiceLocator } from '../../../views/app/@core/Services/locator.service';
import { MainComponent } from '../../../views/app/@core/BaseComponent/main.component';

@Component({
  selector: 'app-list-page-header',
  templateUrl: './list-page-header.component.html'
})
export class ListPageHeaderComponent implements OnInit {
  @Input() isheading = true;
  displayOptionsCollapsed = false;
  @Input() isHeader: boolean = true;
  @Input() showDisplayOptions: boolean = true;
  @Input() showBtnAdd = true;
  @Input() showBtnDropDown = true;
  @Input() showOrderBy = true;
  @Input() showSearch = true;
  @Input() showItemsPerPage = true;
  @Input() showDisplayMode = true;
  @Input() displayMode = 'list';
  @Input() selectAllState = '';
  @Input() itemsPerPage = 10;
  @Input() itemOptionsPerPage = [5, 10, 20];
  @Input() itemOrder = {};
  @Input() itemOptionsOrders = [];
  @Input() CustomButton = [];
  @Input() btnSearch: boolean = true;
  @Input() serachAccessories: boolean = false
  @Input() buttonState: string = '';
  @Input() buttonDisabled: boolean = false;

  @Input() isToolbarlocal: boolean;
  @Input() hideNewlocal: boolean;
  @Input() hideSavelocal: boolean;
  @Input() hideDeletelocal: boolean;
  @Input() hideSearchlocal: boolean;
  @Input() hidePrintlocal: boolean;

  @Input() disabledNewlocal: boolean;
  @Input() disabledSavelocal: boolean;
  @Input() disabledDeletelocal: boolean;
  @Input() disabledSearchlocal: boolean;
  @Input() disabledPrintlocal: boolean;

  @Input() apiController: string = '';
  @Input() apiRoute: string = '';
  @Input() apiParameters: any = [];
  @Input() apiModule: string = '';

  @Input() customHeader: boolean = true;

  private baseComponent: BaseComponent;

  @Output() changeDisplayMode: EventEmitter<string> = new EventEmitter<string>();
  @Output() addNewItem: EventEmitter<any> = new EventEmitter();
  @Output() selectAllChange: EventEmitter<any> = new EventEmitter();
  @Output() searchByButton: EventEmitter<any> = new EventEmitter();
  @Output() searchKeyUp: EventEmitter<any> = new EventEmitter();
  @Output() itemsPerPageChange: EventEmitter<any> = new EventEmitter();
  @Output() changeOrderBy: EventEmitter<any> = new EventEmitter();

  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onPrint: EventEmitter<any> = new EventEmitter();
  @Output() onCustomButton: EventEmitter<any> = new EventEmitter();
  @Output() ExporttoExcel: EventEmitter<any> = new EventEmitter();
  private Lookup: LookupComponent = null;
  LookupInitialized(component: LookupComponent) {

    // this.baseComponent.setLookupComponent(component);
  }

  titlescreen: string = "";
  pathscreen: string = "";

  constructor(private excel: ExcelService, public toolbarEvent: ToolbarEvent, private broadcast: Broadcast) {
    // super()

    // this.baseComponent = ServiceLocator.injector.get(BaseComponent);
    
    this.broadcast.observable<string>("title").subscribe((x) => {
      
      this.titlescreen = x.value;
      this.setTitle();
    });
    this.broadcast.observable<string>("path").subscribe((x) => {
      this.pathscreen = x.value;
    });
    this.broadcast.observable<Boolean>("isToolbar").subscribe((x: any) => {
      this.isToolbarlocal = x.value;
    });
    this.broadcast.observable<Boolean>("hideNew").subscribe((x: any) => {
      this.hideNewlocal = x.value;
    });
    this.broadcast.observable<Boolean>("hideSave").subscribe((x: any) => {
      this.hideSavelocal = x.value;
    });
    this.broadcast.observable<Boolean>("hideDelete").subscribe((x: any) => {
      this.hideDeletelocal = x.value;
    });
    this.broadcast.observable<Boolean>("hideSearch").subscribe((x: any) => {
      this.hideSearchlocal = x.value;
    });
    this.broadcast.observable<Boolean>("hidePrint").subscribe((x: any) => {
      this.hidePrintlocal = x.value;
    });
    this.broadcast.observable<Boolean>("disabledNew").subscribe((x: any) => {
      this.disabledNewlocal = x.value;
    });
    this.broadcast.observable<Boolean>("disabledSave").subscribe((x: any) => {
      this.disabledSavelocal = x.value;
    });
    this.broadcast.observable<Boolean>("disabledDelete").subscribe((x: any) => {
      this.disabledDeletelocal = x.value;
    });
    this.broadcast.observable<Boolean>("disabledSearch").subscribe((x: any) => {
      this.disabledSearchlocal = x.value;
    });
    this.broadcast.observable<Boolean>("disabledPrint").subscribe((x: any) => {
      this.disabledPrintlocal = x.value;
    });

    this.broadcast.observable<string>("SearchController").subscribe((x) => {
      this.apiController = x.value;
    });
    this.broadcast.observable<string>("SearchRoute").subscribe((x) => {
      this.apiRoute = x.value;
    });
    this.broadcast.observable<string>("SearchModule").subscribe((x) => {
      this.apiModule = x.value;
    });
    this.broadcast.observable<any>("SearchParamters").subscribe((x: any) => {
      this.apiParameters = x.value;
    });
    
  }

  async ngOnInit() {
    this.setTitle();
  }
  async setTitle() {
    let path: any = window.location.pathname;
    path = path.split('/APP/');
    
    if (path[1] && path[1] === 'blank-page') {
      this.isheading = false;
    } else {
      this.isheading = true;
    }
  }
  onSelectDisplayMode(mode: string): void {
    this.changeDisplayMode.emit(mode);
  }
  onAddNewItem(): void {
    this.addNewItem.emit(null);
  }
  selectAll(event): void {
    this.selectAllChange.emit(event);
  }
  onChangeItemsPerPage(item): void {
    this.itemsPerPageChange.emit(item);
  }

  onChangeOrderBy(item): void {

    this.itemOrder = item;

    this.changeOrderBy.emit(item);
  }
  seachval: any;
  onSearchKeyUp($event): void {
    let obj = {
      event: $event,
      item: this.itemOrder
    }
    this.seachval = $event;
    this.searchKeyUp.emit(obj);

  }

  async ngAfterViewInit() {
    
    this.broadcast.publish<string>("SearchController", this.apiController);
    this.broadcast.publish<string>("SearchRoute", this.apiRoute);
    this.broadcast.publish<string>("SearchModule", this.apiModule);
    this.broadcast.publish<any>("SearchParamters", this.apiParameters);
    this.cleanValue();
  }

  searchButtton() {
    var val: any = document.getElementById('searchBy');

    let obj = {
      value: val.value ? val.value : this.seachval ? this.seachval.target.value : '',
      item: this.itemOrder
    }

    this.searchByButton.emit(obj);
  }
  cleanValue() {

    var val: any = document.getElementById('searchBy');
    if (val)
      val.value = "";
  }

  isNew() { this.onNew.emit(); }
  isSave() { this.onSave.emit(); }
  isDelete() { this.onDelete.emit(); }
  isPrint() { this.onPrint.emit(); }
  customButton(item) { this.onCustomButton.emit(item) }

  async exportExcel() {
    this.ExporttoExcel.emit();
  }

  // @ViewChild('globalLookup', { read: ViewContainerRef }) public containerLookup: any;
  @ViewChild('globalLookup', { read: ViewContainerRef }) set content(content: ViewContainerRef) {

    if (content) { // initially setter gets called with undefined
      this.broadcast.publish<any>('container', content);
      // this.container = content;
    }
  }



  async customLookup() {
  }
}
