import { NgModule } from '@angular/core';

// This Module's Components
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// This Module's Components
import { ReportViewerComponent } from './report-viewer.component';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule

    ],
    declarations: [
        ReportViewerComponent,
    ],
    exports: [
        ReportViewerComponent,
    ]
})
export class ReportViewerModule {

}
