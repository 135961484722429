// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { BizModalComponent } from './biz-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BizDatatableModule } from '../biz-datatable/biz-datatable.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, BizDatatableModule
    ],
    declarations: [
        BizModalComponent,
    ],
    exports: [
        BizModalComponent,
    ],
    providers: [BsModalService]
})
export class BizModalModule {

}
