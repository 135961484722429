import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { getThemeRadius, setThemeColor, setThemeRadius } from 'src/app/utils/util';

@Component({

    selector: 'report-page',
    templateUrl: 'report-page.component.html',

})
export class ReportPageComponent implements OnInit {

    // selectedColor: string = getThemeColor();

    toggleClass = 'theme-colors';

    constructor(private renderer: Renderer2, private router: Router) {

    }
    ngOnInit(): void { }
    async OpenReport() {
        debugger
        // window.open("https://ehsan.poultry.live/APP/512/512101/512101101");
        // console.log(window.location.href);
        this.router.url.split('?')[0];
        const routes = '/APP/512/512101/512101101'
        let url = window.location.origin;
        let comb = url + routes
        window.open(comb)

    }
}
